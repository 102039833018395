@import '../theme.scss';

#footerMain {
  background-color: $color-black;
  padding: 0 150px;
  width: 100%;

  > .inner {
    max-width: 1660px;
    margin: 0 auto;
    padding: 50px 0;
    grid-template-columns: auto 1fr;
    grid-template-areas: 'logo nav';
    grid-gap: clamp(20px, 3vw, 50px);
    align-items: start;

    .logo {
      margin-bottom: 20px;

      a {
        display: inline-block;
      }
    }

    nav {
      grid-area: nav;

      ul {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: clamp(20px, 8vw, 150px);
        align-items: start;
        justify-content: right;

        .linkSet {
          height: 100%;

          .heading {
            font-size: 22px;
            position: relative;
            margin-bottom: 20px;
            display: inline-block;
            width: auto;

            &::after {
              content: '';
              display: block;
              width: 60%;
              margin: 0 auto;
              margin-top: 10px;
              background-color: $color-red-light;
              height: 2px;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }

          > a {
            display: block;
            width: 100%;
            color: $color-white;
            font-size: 16px;
            line-height: 1.25;
            letter-spacing: 0.02em;
            padding-bottom: 5px;
          }

          .social {
            padding-top: 10px;

            a {
              display: inline-block;
              vertical-align: middle;
              margin-right: 10px;
              position: relative;

              > svg {
                position: absolute;
                top: 50%;
                left: 50%;
                translate: -50% -50%;
              }

              &:nth-last-child(1) {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1250px) {
  #footerMain {
    padding: 0 $standard-padding-x;
  }
}

@media (max-width: $screenBreak-mobile) {
  #footerMain {
    > .inner {
      grid-template-columns: 1fr;
      grid-template-areas:
        'logo'
        'nav';
      grid-gap: 20px;
      text-align: center;

      nav {
        ul {
          grid-template-columns: 1fr;

          .linkSet {
            .heading {
              &::after {
                left: 20%;
              }
            }
          }
        }
      }
    }
  }
}
